import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
// import { Redirect } from "react-router-dom";
// Routing
import PrivateRoute from "./components/routing/PrivateRoute";

// Screens
// import PrivateScreen from "./components/screens/PrivateScreen";
import LoginScreen from "./components/screens/LoginScreen";
import RegisterScreen from "./components/screens/RegisterScreen";
import ForgotPasswordScreen from "./components/screens/ForgotPasswordScreen";
import ResetPasswordScreen from "./components/screens/ResetPasswordScreen";
import FrontPage from "./components/screens/FrontPage"
// import InventoryScreen from "./components/screens/InventoryScreen";
import Home from "./components/screens/Home"
import Rotation from "./components/screens/Rotation"
import  Settings from "./components/screens/Settings";
import BasketsPage from "./components/screens/BasketsPage"
import PricingPage from "./components/screens/PricingPage"
import PrivacyPolicyScreen from "./components/screens/PrivacyPolicyScreen"
import TermsOfUseScreen from "./components/screens/TermsOfUseScreen"
import ContactUsScreen from "./components/screens/ContactUsScreen"
import PricingNoAuthPage from "./components/screens/PricingNoAuthPage"
import PaymentSuccess from "./components/screens/PaymentSuccess";
import PaymentCanceled from "./components/screens/PaymentCanceled";
import RecoverPasswordIos from "./components/screens/RecoverPasswordIos"
import BeeHealthyContactUsScreen from "./components/screens/BeeHealthyContactUsScreen"
// Components
import Navbar from './components/screens/Navbar'
import { useEffect, useState } from "react";
import DeletionForm from "./components/screens/DeletionForm";

const App = () => {

  const [logedin, setLogedin] = useState(true) 
  //it remove authToken from local storage in one hour
  //other part of code in LoginScreen.js


  useEffect(() => {
const myInterval = setInterval(function(){
  var mins = 119; 
  var now = new Date().getTime();
  var setupTime = localStorage.getItem('setupTime');
      if(now-setupTime > mins*60*1000 && localStorage.getItem("authToken")) {
        localStorage.removeItem("authToken")
        localStorage.removeItem("setupTime")
          
          setLogedin(!logedin)
      }
}, 5000)

return () => clearInterval(myInterval)

}, [logedin]);


  return (
    <Router>
      <div className="app">
        <Navbar />
        <Switch>
          <PrivateRoute exact path="/inventory" component={Home} />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute exact path="/rotation" component={Rotation} />
          <PrivateRoute exact path="/pricing" component={PricingPage} />
          <PrivateRoute exact path="/baskets" component={BasketsPage} />
          {/* <PrivateRoute exact path="/Home" component={Home} /> */}
          {/* <PrivateRoute exact path="/Upload" component={Upload} /> */}
          <Route exact path="/pricingnoauth" component={PricingNoAuthPage} />
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/" component={FrontPage}/>
          <Route exact path="/privacy" component={PrivacyPolicyScreen}/>
          <Route exact path="/terms" component={TermsOfUseScreen}/>
          <Route exact path="/deletionform" component={DeletionForm}/>
          <Route exact path="/contactus" component={ContactUsScreen}/>
          <Route exact path="/register" component={RegisterScreen} />
          <Route exact path="/paymentsuccess" component={PaymentSuccess} />
          <Route exact path="/paymentcanceled" component={PaymentCanceled} />
          <Route exact path="/RecoverPassword" component={RecoverPasswordIos} />
          {/* BeeHealthy */}
          <Route exact path="/beehealthycontactus" component={BeeHealthyContactUsScreen}/>

          <Route
            exact
            path="/forgotpassword"
            component={ForgotPasswordScreen}
          />
          <Route
            exact
            path="/passwordreset/:resetToken"
            component={ResetPasswordScreen}
          />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
