import React, {  useState, useEffect} from 'react';

function BeeHealthyContactUsScreen() {
    const [disableButton, setDisableButton] = useState(false)
    const [msg, setMsg] = useState(null)
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState(
        {email: "",subject:"Bee Healthy Contact Form", message:""}
    )

    function handleChange(event){
        setFormData(prevFormData =>  {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    const submitMessage = async (formData) => {
        try {
            
            const response = await fetch('/api/public/contactus', {
                // credentials: 'include',
                method: 'POST',
                body: JSON.stringify(formData),
                headers: { 'Content-Type': 'application/json',
                // Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
            });
            const data = await response.json()
            setMsg(data)
        } catch (err) {
            console.error(err);
        }     
    };
    const submitButton = () =>{
        submitMessage(formData)
        setDisableButton(true)
    }
    
    useEffect(() => {
        if (msg) {
            setShow(true);
            setInterval(() => {
                setShow(false);
            }, 5000);
        }
    }, [msg]);
  return (
    <div className='main'>
        <h1 className='terms--header'>Contact Us</h1>
        <h1 className='terms--header'>BeeHealthy App</h1>

        <div className='upload'>
            <form  className="inventory-screen__form" >
               <div className="form-group">
                    {show && 
                        <div className=" popup--window">
                            <div style={{color:"red"}}className='popup--message'>🦆 {msg.msg} 🦆</div>
                        </div>
                    }
                    <input
                        type="email"
                        placeholder="Your email"
                        onChange={handleChange}
                        name="email"
                        value={formData.email}
                        className="border--radius"
                        
                        maxLength="500"
                        required={true}
                    />
                        <textarea 
                        style={{height: '200px'}}
                        type="text"
                        placeholder="Your message"
                        onChange={handleChange}
                        name="message"
                        value={formData.message}
                        className="border--radius"
                        maxLength="1000"
                        required={true}
                    />
                    <button onClick={submitButton} className="btn btn-primary border--radius"  type="submit" disabled={disableButton}>
                    Submit
                    </button>
               </div> 
            </form>

        </div>
    </div>
  )
}

export default BeeHealthyContactUsScreen
